import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GlobalService } from '../../service/global_service';
import { MenuService } from '../../service/menu.serice';
import { SidenavService } from '../../service/sidenav.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Subject, takeUntil, tap } from 'rxjs';
import { TokenStorageService } from 'apps/cambix/src/utils/services/token/token.storage.service';
import { PreloaderService } from '../../service/preloader.service';
import { RegisterOperationHttp } from 'apps/cambix/src/app/cambix/dashboard/change-dollar/commons/http/register-operation.http';
import { Router } from '@angular/router';
import { AppObservable } from 'apps/cambix/src/utils/observables/app.observable';
import { IProfileUser } from 'apps/cambix/src/app/cambix/dashboard/profile-user/interface/profile.interface';
const getStyles = (...args: string[]) => ['listMenu', ...args].filter(Boolean);

@Component({
  selector: 'cbx-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() listMenu: any[] = [];
  @Input() type: 'MenuDefault' | 'MenuDashboard' | 'MenuSidenav' = 'MenuDefault';

  @Output() emitMenuSelect: EventEmitter<any> = new EventEmitter();

  private destroyed = new Subject<void>();
  public get typeClass(): string[] {
    return getStyles(this.type);
  }

  arraydatosHorarios: any[] = [];
  arrayImagenesBancos: any[] = [];
  arrayNombresBancos: any[] = [];

  toolHorarios: any = '';
  toolHorariosPersona: any = '';
  toolBancosAsociados: any = '';
  arrayNameBancosAsociados: any[] = [];
  itemsArray: any[] = [];

  positionToolHorariosResponsive: string = 'up-large';
  positionToolBancosResponsive: string = 'right-center-nameBancos';

  sesionUsuario: any;

  horarios_general: any;
  horarios_bancomers: any;
  lastCode: number = 1;

  constructor(
    private service: GlobalService,
    private serviceMenu: MenuService,
    private serviceSidenav: SidenavService,
    private breakpointObserver: BreakpointObserver,
    private tokenStorageService: TokenStorageService,
    private servicePreloader: PreloaderService,
    private serviceRegisterOperation: RegisterOperationHttp,
    private globalService: GlobalService,
    private router: Router,
    private appObservable: AppObservable,
  ) {
    this.appObservable.changeProfile$
      .pipe(takeUntil(this.destroyed))
      .subscribe((resp: IProfileUser | null) => {
        if (resp) {
          this.sesionUsuario = resp;
          this.onChangeMenus(resp.userType);
        }
      });

    this.toolHorariosPersona =
      `
    <div class="font-secondary-regular-12">
      <div class="espaciado">` +
      'Horario Extendido hasta' +
      ` </div>
      <div class="espaciado">` +
      'las 11:45 p.m' +
      ` </div>
      <div class="font-secondary-regular-10">` +
      '(Para operaciones solo con' +
      ` </div>
      <div class="font-secondary-regular-10">` +
      'cuentas Bancom)' +
      ` </div>
    </div>`;
  }

  ngOnInit() {
    const datosSession: any = this.tokenStorageService.getStoreSessionUser();
    this.sesionUsuario = JSON.parse(datosSession);
    this.onChangeMenus(this.sesionUsuario.userType);

    this.onGetHorarios();

    this.arrayNombresBancos = this.service.getNombreMasBancos();
    this.arrayImagenesBancos = this.service.getImgBancos();

    this.onGenerateToolResponsive();
    this.arrayNombresBancos.forEach((elemento) => {
      this.arrayNameBancosAsociados.push(
        `<div class="font-secondary-regular-14">` + elemento.nombreBanco + `</div>`,
      );
    });

    this.toolBancosAsociados = this.arrayNameBancosAsociados.join('');
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onChangeMenus(profile: string) {
    this.globalService
      .getMenu(profile)
      .pipe(takeUntil(this.destroyed))
      .subscribe((menuOptions) => (this.listMenu = menuOptions));
  }

  onGetHorarios() {
    this.serviceRegisterOperation
      .getParameters()
      .pipe(
        tap((parameters) => {
          if (this.sesionUsuario.userType === 'EMP') {
            this.horarios_general = null;
          } else {
            this.horarios_general = parameters.find(
              (find) => find.code === 'GENERAL_CUSTOMER_SCHEDULE',
            );
          }
          this.horarios_bancomers = parameters.find(
            (find) => find.code === 'BANK_CUSTOMER_SCHEDULE',
          );

          this.toolHorarios =
            `
        <div class="verMasHorarios font-secondary-regular-14">
          <div class="espaciado">` +
            'Para clientes Bancom' +
            ` </div>
          <div>` +
            this.horarios_bancomers.value +
            `</div>
        </div>`;
        }),
      )
      .subscribe();
  }

  onGenerateToolResponsive() {
    this.breakpointObserver.observe(Breakpoints.Medium).subscribe((state: BreakpointState) => {
      this.positionToolHorariosResponsive = state.matches
        ? 'up-small'
        : this.positionToolHorariosResponsive;
      this.positionToolBancosResponsive = state.matches
        ? 'medium'
        : this.positionToolBancosResponsive;
    });

    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((state: BreakpointState) => {
      this.positionToolHorariosResponsive = state.matches
        ? 'up-small'
        : this.positionToolHorariosResponsive;
      this.positionToolBancosResponsive = state.matches
        ? 'left-small'
        : this.positionToolBancosResponsive;
    });
  }

  onHiddenSidenav() {
    this.serviceSidenav.setisActiveMenuUser(false);
  }

  onActive(router: string, codeMenu: number) {
    this.lastCode = codeMenu;

    if (codeMenu === 8106) window.open('https://cambix.com.pe/blog', '_self');
    if (codeMenu === 4) {
      this.serviceSidenav.setisActiveMenuUser(false);
      //no tiene token para el switch
      if (!this.sesionUsuario.token) {
        this.appObservable.setRegisterBusiness(true);
        this.router.navigateByUrl('cambix-business/auth/registro');
        return;
      }

      this.onToggleProfile();
    } else {
      const datosSession: any = this.tokenStorageService.getStoreSessionUser();
      this.sesionUsuario = JSON.parse(datosSession);

      if (this.sesionUsuario.userType === 'PER') {
        this.serviceMenu.setUpdateMenu(codeMenu, router);
      } else {
        this.serviceMenu.setUpdateMenuBusiness(codeMenu, router);
      }
      this.serviceSidenav.setisActiveMenuUser(false);
    }
  }

  onToggleProfile() {
    this.servicePreloader.setpreloader(true);
    this.tokenStorageService.toggleUserProfile().subscribe({
      next: (userData: IProfileUser) => {
        this.sesionUsuario = userData;
        this.onChangeMenus(this.sesionUsuario.userType);

        this.servicePreloader.setpreloader(true);

        if (userData.userType === 'PER') {
          this.router.navigateByUrl('cambix/dashboard');
          if (userData.hasCompany === false) this.appObservable.setRegisterBusiness(true);
        } else {
          this.router.navigateByUrl('cambix-business/dashboard');
        }
      },
      error: (e) => this.servicePreloader.setpreloader(false),
    });
  }
}
