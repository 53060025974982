import { Injectable } from '@angular/core';
import { environment } from 'apps/cambix/src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  saveToLocalStorage(key: string, value: string): void {
    localStorage.setItem(key, this.encrypt(value));
  }

  getFromLocalStorage(key: string): string | null {
    let data = localStorage.getItem(key);
    return data ? this.decrypt(data) : null;
  }

  removeFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }

  clearLocalStorage(): void {
    localStorage.clear();
  }

  saveToSessionStorage(key: string, value: string): void {
    sessionStorage.setItem(key, this.encrypt(value));
  }

  getFromSessionStorage(key: string): string | null {
    let data = sessionStorage.getItem(key);
    return data ? this.decrypt(data) : null;
  }

  removeFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }

  clearSessionStorage(): void {
    sessionStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, environment.keyencrypt).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, environment.keyencrypt).toString(CryptoJS.enc.Utf8);
  }
}
