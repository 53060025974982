import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IProfileUser } from '../../app/cambix/dashboard/profile-user/interface/profile.interface';

@Injectable({
  providedIn: 'root',
})
export class AppObservable {
  private temporaryCookies = new BehaviorSubject<any>(null);
  public temporaryCookies$ = this.temporaryCookies.asObservable();

  private flgRegBusiness = new BehaviorSubject<boolean>(false);
  public flgRegBusiness$ = this.flgRegBusiness.asObservable();

  private _changeProfile = new BehaviorSubject<IProfileUser | null>(null);
  public changeProfile$ = this._changeProfile.asObservable();

  constructor() {}

  getTemporalCookies(): any {
    return this.temporaryCookies.getValue();
  }

  setTemporalCookies(val: unknown) {
    this.temporaryCookies.next(val);
  }

  //Para el registro
  getRegisterBusiness(): any {
    return this.flgRegBusiness.getValue();
  }

  setRegisterBusiness(val: boolean) {
    this.flgRegBusiness.next(val);
  }

  //Para cambio de Perfil
  getNewProfile(): any {
    return this._changeProfile.getValue();
  }

  setNewProfile(val: IProfileUser) {
    this._changeProfile.next(val);
  }
}
