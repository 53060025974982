import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { RefreshAccessService } from '../../services/refresh-access/refresh-access.service';
import { STORAGE_KEY } from '../../constants/storage';
import { TokenStorageService } from '../../services/token/token.storage.service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private readonly refreshAccessService = inject(RefreshAccessService);
  private readonly serviceToken = inject(TokenStorageService);
  private readonly router = inject(Router);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isTokenRequest = request.url.includes('/auth/refresh-token');
    const accessToken = this.serviceToken.getToken();

    if (accessToken && !isTokenRequest) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` },
      });
    }

    if (this.refreshAccessService.refreshToken$ && !isTokenRequest) {
      return this.addToQueue(request, next);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized && !isTokenRequest && !error.url?.includes('users/login')) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.refreshAccessService.token().pipe(
      switchMap((newToken) => next.handle(this.addToken(request, newToken))),
      catchError((error) => {
        if (error.url?.includes('/auth/refresh-token')) {
          this.onFailedRenewal();
        }
        return throwError(() => error);
      }),
    );
  }

  private addToken(req: HttpRequest<unknown>, accessToken: string | null): HttpRequest<unknown> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  private addToQueue(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Add the request to the queue to be executed after the token is renewed
    return this.refreshAccessService.token().pipe(
      switchMap((newToken) => next.handle(this.addToken(request, newToken))),
      catchError((error) => throwError(() => error)),
    );
  }

  private onFailedRenewal() {
    localStorage.removeItem(STORAGE_KEY.firstLogin);
    this.serviceToken.removeStoreSessionUser();
    this.serviceToken.removeToken();
    this.router.navigateByUrl('/');
  }
}
