import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/cambix/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SubscriptionKeyInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqClone = req.clone({
      setHeaders: {
        'Ocp-Apim-Subscription-Key': environment.OcpApimSubscriptionKey,
      },
    });

    return next.handle(reqClone);
  }
}
