import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

const getStyles = (...args: string[]) => [...args].filter(Boolean)

@Component({
  selector: 'cbx-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent {

  @Input() typeInput:  "Classic" | "ClassicArray" | "Others"  = "Classic";
  @Input() existeFecha : any = "";
  @Output() public enviarFecha = new EventEmitter();


  arrayMeses = [
    { name : 'Ene', code : 1},
    { name : 'Feb', code : 2},
    { name : 'Mar', code : 3},
    { name : 'Abr', code : 4},
    { name : 'May', code : 5},
    { name : 'Jun', code : 6},
    { name : 'Jul', code : 7},
    { name : 'Ago', code : 8},
    { name : 'Set', code : 9},
    { name : 'Oct', code : 10},
    { name : 'Nov', code : 11},
    { name : 'Dic', code : 12}
  ]


  public get typeInputs(): string[] {
    return getStyles(this.typeInput)
  }

  selectedDay: any;
  days: string[] =  ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
  months: any[] = this.arrayMeses
  years: any[] = [];
  yearsFilter: any[] = [];

  selectedMonth: any = 0;
  selectedYear: any= 0;
  dateYearSelect : any = "";
  dateMonthSelect  : any = "";
  currentDate = new Date();
  calendar: Array<Array<number | null>> = [];
  errorFecha: boolean =false;

  constructor() {
    this.updateYears();
    this.updateCalendar();
  }


  ngOnChanges(changes: SimpleChanges) {
    if(this.existeFecha){
      this.onSetExisteDate();
    }
  }

  onSetExisteDate(){
    const  fechaexistente = this.existeFecha.split('/');
    // Destructurando fecha seleccionada.
    const [daySelect, monthSelect, yearSelect] = fechaexistente;

    if(daySelect != 'dd' ){
      this.selectedDay =  +daySelect;
    }
    if(monthSelect != 'mm' ){
      this.selectedMonth = +monthSelect;
      this.dateMonthSelect  = this.arrayMeses[+monthSelect- 1].name;
    }
    if(yearSelect != 'yyyy' ){
      this.selectedYear = +yearSelect
      this.dateYearSelect = +yearSelect;
    }

    this.updateCalendar();
  }

  updateYears() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 12; i <= currentYear; i++) {
      this.years.push({name : i});
      this.yearsFilter.push({name : i});
    }
  }

  updateCalendar() {
    this.calendar = [];
    const firstDayOfMonth = new Date(this.selectedYear, (this.selectedMonth-1), 1).getDay();
    const lastDayOfMonth = new Date(this.selectedYear, this.selectedMonth, 0);

    let week: Array<number | null> = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      week.push(null); // Agrega días en blanco antes del primer día del mes
    }
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      week.push(i);
      if ((firstDayOfMonth + i - 1) % 7 === 6 || i === lastDayOfMonth.getDate()) {
        this.calendar.push(week);
        week = [];
      }
    }

  }

  selectDate(day: number) {
    let addZero:any = day > 9 ? day : ("0" + day) ;
    this.selectedDay = addZero;
    let month = this.selectedMonth ? (this.selectedMonth > 9 ? this.selectedMonth  : "0" + this.selectedMonth) : 'mm';
    let year =  this.selectedYear ? this.selectedYear : 'yyyy';

    this.enviarFecha.emit( this.selectedDay + '/' + month  + '/' + year);
    this.updateCalendar();
  }

  onSelectYear(year : any){
    this.selectedYear = year.name;
    this.dateYearSelect = year.name;
    let dia =  this.selectedDay ? (this.selectedDay > 9 ? this.selectedDay  : "0"+ this.selectedDay) : 'dd';
    let month = this.selectedMonth ? (this.selectedMonth > 9 ? this.selectedMonth : "0" + this.selectedMonth) : 'mm';

    this.enviarFecha.emit(dia + '/' + month  + '/' +  this.dateYearSelect);
    this.updateCalendar();
  }

  onSelectMonth(month : any){
    this.selectedMonth =   month.code > 9 ? month.code  : "0" + month.code;;
    this.dateMonthSelect = month.name;
    let dia =  this.selectedDay ? (this.selectedDay > 9 ? this.selectedDay  : "0" + this.selectedDay) : 'dd';
    let year = this.selectedYear ? this.selectedYear  : 'yyyy';

    this.enviarFecha.emit(dia + '/' + this.selectedMonth  + '/' + year);
    this.updateCalendar();
  }


}

