/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'apps/cambix/src/environments/environment';
import { IProfileUser } from '../interface/profile.interface';
import { UserProfileHttpModule } from './http.module';

@Injectable({
  providedIn: UserProfileHttpModule,
})
export class ProfileUserHttp {
  private pathv2: string;
  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.api_url}`;
    this.pathv2 = 'v2/users/';
  }

  getDataUserToken(): Observable<IProfileUser> {
    return this.http.get<IProfileUser>(`${this.apiUrl}${this.pathv2}me`);
  }
}
