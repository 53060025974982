import * as CryptoJS from 'crypto-js'; 

export class CryptoUtils {

    public generateKey(): { key: string; iv: string } {
        const key = CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Base64); // 256-bit
        const iv = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Base64);  // 128-bit
        return { key, iv };
    }

    public encryptData(data: string, key: string, iv: string): string {
        const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Base64.parse(key), {
          iv: CryptoJS.enc.Base64.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        return encrypted.toString();
    }

    public decryptData(encryptedData: string, key: string, iv: string): string {
        const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Base64.parse(key), {
          iv: CryptoJS.enc.Base64.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    } 
}

