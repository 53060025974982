/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IAccountResV2,
  IAccountResV3,
  IActivityGetAllResponse,
  ICompanySearchResponse,
  ICompanySearchResponseV3,
  ICreated,
  ILoginV3,
  IMoneyShippingReason,
  ParametersRes,
  PostRes,
  SplaftsRes,
} from '../interfaces/register-operation-response.interface';
import { environment } from 'apps/cambix/src/environments/environment';
import {
  IActivityGetAllRequest,
  ICompanyCreateRequest,
  OperationReq,
} from '../interfaces/register-operation-request.interface';
import { ICarouselDate } from 'libs/cambix/ui/src/interfaces/carousel.interface';

@Injectable({
  providedIn: 'root',
})
export class RegisterOperationHttp {
  private apiUrl: string;
  private path: string;
  private pathV2: string;
  private pathV3: string;

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.api_url}`;
    this.path = 'v1/accounts/';
    this.pathV2 = 'v2/accounts';
    this.pathV3 = 'v3/accounts';
  }

  getAccountByCCI(currencyCode: string, cci: string, ruc = null): Observable<IAccountResV2> {
    let params = new HttpParams().append('currencyCode', currencyCode).append('cci', cci);

    if (ruc) {
      params = params.set('ruc', ruc);
    }

    return this.http.get<IAccountResV2>(`${this.apiUrl}${this.pathV2}/account`, { params });
  }

  getAccountByUser(currencyCode: string): Observable<IAccountResV2[]> {
    const params = new HttpParams().append('currencyCode', currencyCode);

    return this.http.get<IAccountResV2[]>(`${this.apiUrl}${this.pathV2}?`, { params });
  }

  saveFrecuentAccount(idAccount: number): Observable<PostRes> {
    const accountReq = { idAccount };
    return this.http.post<PostRes>(`${this.apiUrl}${this.path}account/`, accountReq);
  }

  getAccountByCciV3(
    currencyCode: string,
    cci: string,
    originAccount: boolean = false,
    ruc = null,
  ): Observable<IAccountResV3> {
    let params = new HttpParams().append('currencyCode', currencyCode).append('cci', cci);

    if (ruc) {
      params = params.set('ruc', ruc);
    }
    if (originAccount) {
      params = params.set('originAccount', originAccount.toString());
    }
    return this.http.get<IAccountResV3>(`${this.apiUrl}${this.pathV3}/account`, { params });
  }

  loginV3(reCaptchaKey: string, cellphone: string, password: string): Observable<any> {
    const authReq: ILoginV3 = {
      platformCode: 'WEB',
      cellphone,
      password,
      reCaptchaKey,
    };
    return this.http.post(`${this.apiUrl}v3/auth/login`, authReq);
  }

  loginV4(
    email: string,
    password: string,
    reCaptchaKey: string,
    userType: 'PER' | 'EMP',
  ): Observable<any> {
    const authReq: any = {
      platformCode: 'WEB',
      userType,
      email,
      password,
      reCaptchaKey,
    };
    return this.http.post(`${this.apiUrl}v4/users/login`, authReq);
  }

  saveOperation(operationReq: OperationReq): Observable<PostRes> {
    return this.http.post<PostRes>(
      this.apiUrl + 'v2/exchange-operations/exchange-operation',
      operationReq,
    );
  }

  getSplafts(): Observable<SplaftsRes[]> {
    // TODO: Revisar en dónde se está llamando éste servicio, si es necesario llamarlo múltples veces y como cachearlo
    return this.http.get<SplaftsRes[]>(this.apiUrl + 'v2/splafts');
  }

  getMoneyShippingReasonList(): Observable<IMoneyShippingReason[]> {
    // TODO: Revisar en dónde se está llamando éste servicio, si es necesario llamarlo múltples veces y como cachearlo
    return this.http.get<IMoneyShippingReason[]>(this.apiUrl + 'v2/money-shipping-reasons');
  }

  getParameters(): Observable<ParametersRes[]> {
    // TODO: Revisar en dónde se está llamando éste servicio, si es necesario llamarlo múltples veces y como cachearlo nota: Puede cambiar un poco más seguido.
    return this.http.get<ParametersRes[]>(this.apiUrl + 'v2/parameters');
  }

  getActivities(request: IActivityGetAllRequest): Observable<IActivityGetAllResponse[]> {
    const params = new HttpParams()
      .append('personType', request.personType)
      .append('activityName', request.activityName ?? '');
    return this.http.get<IActivityGetAllResponse[]>(this.apiUrl + 'v1/activities', { params });
  }

  searchRuc(ruc: string): Observable<ICompanySearchResponse> {
    const params = new HttpParams().append('ruc', ruc);
    return this.http.get<ICompanySearchResponse>(`${this.apiUrl}v1/companies/company`, { params });
  }

  searchRucV3(ruc: string): Observable<ICompanySearchResponseV3> {
    const params = new HttpParams().append('ruc', ruc);
    return this.http.get<ICompanySearchResponseV3>(`${this.apiUrl}v3/companies`, { params });
  }

  registerCompany(request: ICompanyCreateRequest): Observable<ICreated> {
    return this.http.post<ICreated>(`${this.apiUrl}v1/companies/company`, request);
  }

  getExternalProducts(): Observable<ICarouselDate[]> {
    return this.http.get<ICarouselDate[]>(this.apiUrl + 'v1/external-products');
  }
}
