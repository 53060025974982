import { APP_INITIALIZER, CSP_NONCE, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe, DOCUMENT } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire/compat';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BiometricGlobalService } from './cambix/shared/observables/biometric-global.service';
import { newRegisterObservable } from './cambix/shared/observables/auth-public-observabe';
import { CambixUiModule } from '@cambix-web-v2.0/cambix/ui';
import { MigrationGlobalService } from './cambix/shared/observables/migration-global.service';
import { environment } from '../environments/environment';
import { AppObservable } from '../utils/observables/app.observable';
import { MatIconModule } from '@angular/material/icon';
import { CookieService } from 'ngx-cookie-service';
import { HomeCookiesComponent } from '../utils/dialogs/home-cookies/home-cookies.component';
import { DialogDeleteComponent } from './cambix/shared/dialog-confirm/dialog-delete/dialog-delete.component';
import { DialogOtpCellphoneComponent } from './cambix/shared/dialog-otp-cellphone/dialog-otp-cellphone.component';
import { MigrationChangePassHttp } from './cambix/migration/http/migrationChangePass.http';
import { DialogValidateOperationComponent } from './cambix/shared/dialog-validate-operation/dialog-validate-operation.component';
import { CookiesPolicyComponent } from './cambix/legal/cookies-policy/cookies-policy.component';
import { CookiesConfigComponent } from '../utils/dialogs/cookies-config/cookies-config.component';
import { GlobalService } from 'libs/cambix/ui/src/service/global_service';
import { ProfileUserHttp } from './cambix/dashboard/profile-user/http/profile-user.service';
import { DialogLegalComponent } from './cambix/shared/dialog-legal/dialog-legal.component';
import { TermsConditionsComponent } from './cambix/legal/terms-conditions/terms-conditions.component';
import { PersonalDataProcessingPolicyComponent } from './cambix/legal/personal-data-processing-policy/personal-data-processing-policy.component';
import { AuthorizationsAdditionalPusposesComponent } from './cambix/legal/authorizations-additional-pusposes/authorizations-additional-pusposes.component';
import { DialogRegisterBusinessComponent } from './cambix/shared/dialog-register-business/dialog-register-business.component';
import { SecureURLComponent } from './secure-url/secure-url.component';
import { LoadChunkErrorHandler } from '../utils/services/load-chunk/load-chunk.service';
import { UtmInterceptor } from '../utils/interceptor/utm/utm.interceptor';
import { UtmService } from '../utils/services/utm/utm.service';
import { CacheService } from '../utils/services/cache/cache.service';
import { AppVersionService } from '../utils/services/app-version/app-version.service';
import { CacheInterceptor } from '../utils/interceptor/cache/cache.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtInterceptor } from '../utils/interceptor/jwt/jwt.interceptor';
import { SubscriptionKeyInterceptor } from '../utils/interceptor/subscription/subscription-key.interceptor';
import { ConfigService } from '../utils/services/access/config.service';
import { CryptoUtils } from './cambix/auth/service/cryptoUtils.service';

export function clearIndexHtmlCache(cacheService: CacheService) {
  return () => cacheService.clearCacheForFile('index.html');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeCookiesComponent,
    DialogDeleteComponent,
    DialogOtpCellphoneComponent,
    DialogValidateOperationComponent,
    DialogRegisterBusinessComponent,
    CookiesPolicyComponent,
    CookiesConfigComponent,
    DialogLegalComponent,
    TermsConditionsComponent,
    PersonalDataProcessingPolicyComponent,
    AuthorizationsAdditionalPusposesComponent,
    SecureURLComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CambixUiModule,
    NgxSkeletonLoaderModule.forRoot(),
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    MatDialogModule,
    MatIconModule,
    MatSlideToggleModule,
    NgbModule,
  ],
  providers: [
    AppObservable,
    AppVersionService,
    BiometricGlobalService,
    CurrencyPipe,
    CookieService,
    CacheService,
    GlobalService,
    MigrationGlobalService,
    MigrationChangePassHttp,
    newRegisterObservable,
    ProfileUserHttp,
    UtmService,
    CryptoUtils,
    {
      provide: APP_INITIALIZER,
      useFactory: clearIndexHtmlCache, //<--- calling function on Angular App init
      deps: [CacheService], //<---- Important deps
      multi: true,
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: HTTP_INTERCEPTORS, useClass: SubscriptionKeyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UtmInterceptor, multi: true },
    { provide: ErrorHandler, useClass: LoadChunkErrorHandler },
    {
      provide: CSP_NONCE,
      useFactory: (document: Document) => {
        const meta = document.querySelector('meta[name="csp-nonce"]');
        return meta ? meta.getAttribute('content') : null;
      },
      deps: [DOCUMENT]
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
