import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { STORAGE_KEY } from '../../constants/storage';
import { TokenRes } from '../../interface/token/token.response';
import { environment } from 'apps/cambix/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RefreshAccessService {
  refreshToken$: Observable<string> | null = null;

  private readonly http = inject(HttpClient);
  private readonly storageService = inject(StorageService);

  token(): Observable<string> {
    if (this.refreshToken$) {
      return this.refreshToken$;
    }

    this.refreshToken$ = of(
      this.storageService.getFromLocalStorage(STORAGE_KEY.refreshAuthToken),
    ).pipe(
      switchMap((refreshToken) => {
        return this.http.post<TokenRes>(environment.api_url + 'v2/auth/refresh-token', {
          refreshToken,
        });
      }),
      tap((resp) => {
        this.storageService.saveToLocalStorage(STORAGE_KEY.authToken, resp.token);
        this.storageService.saveToLocalStorage(STORAGE_KEY.refreshAuthToken, resp.refreshToken);
      }),
      map((resp) => resp.token),
      catchError((error) => {
        this.refreshToken$ = null;
        return throwError(() => error);
      }),
      finalize(() => {
        this.refreshToken$ = null;
      }),
      shareReplay(1),
    );

    return this.refreshToken$;
  }
}
