/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as CryptoJS from 'crypto-js';

var keyG = '';
var ivG = '';


// add_header X-Secret-Key "/6Z9n4o+Sp95fv00SUAdaarq8/7VH6kTed9Rq9MSMkI=";
//     add_header X-Secret-IV "HZF2yQTEIrD4w1DP+AcOzg==";
async function loadConfig() {
  const response = await fetch('/assets/access/Config.json');
  const config = await response.json();
  const headers = response.headers;
  const key = headers.get('X-Secret-Key') || '/6Z9n4o+Sp95fv00SUAdaarq8/7VH6kTed9Rq9MSMkI=';
  const iv = headers.get('X-Secret-IV') || 'HZF2yQTEIrD4w1DP+AcOzg==';
  keyG = key;
  ivG = iv;
  const decryptedConfig = await decryptConfig(config);
  Object.assign(environment, decryptedConfig);
  (window as any).appConfig = { ...config, key, iv }; // Guardamos todo en el objeto global
}

async function decryptConfig(config: any): Promise<any> {
  return {
    // 🔹 Variables encriptadas en Azure
    secretKeyAES: await decryptValue(config.secretKeyAES),
    secretIv: await decryptValue(config.secretIv),
    keyencrypt: await decryptValue(config.keyencrypt),
    OcpApimSubscriptionKey: await decryptValue(config.OcpApimSubscriptionKey),

    appInsights: {
      instrumentationKey: await decryptValue(config.instrumentationKey)
    },
    
    firebaseConfig: {
      apiKey: await decryptValue(config.firebaseConfig.apiKey),
      appId: await decryptValue(config.firebaseConfig.appId),
      measurementId: await decryptValue(config.firebaseConfig.measurementId),
    },

    biometricConfig: {
      API_KEY: await decryptValue(config.biometricConfig.ApiKey),
    }
  };
}

async function decryptValue(encryptedData: string): Promise<string> {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Base64.parse(keyG), {
    iv: CryptoJS.enc.Base64.parse(ivG),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

loadConfig().then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
