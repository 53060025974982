export const environment = {
  production: false,
  showDialogRegisterBusiness: true,
  release: '7040028151709',
  domain: 'cambix-qa.azurewebsites.net',
  api_url: 'https://cambix-backend-qa.azurewebsites.net/',
  domains: ['localhost', 'cambix-qa.azurewebsites.net'],
  version: 'v1/',

  secretKeyAES: '',
  secretIv: '',
  keyencrypt: '',
  OcpApimSubscriptionKey: '323dea19109d4fd9a628a9fb4b4dfbdf',
  
  appInsights: {
    instrumentationKey: ''
  },

  firebaseConfig: {
    apiKey: '',
    authDomain: 'cambix-2235a.firebaseapp.com',
    databaseURL: 'https://cambix-2235a.firebaseio.com',
    projectId: 'cambix-2235a',
    storageBucket: 'cambix-2235a.appspot.com',
    messagingSenderId: '522275500605',
    appId: '',
    measurementId: '',
  },

  biometricConfig: {
    URL_BASE: 'https://adocolombia-qa.ado-tech.com/BanComercioPeruQA',
    PROJECT_NAME: 'BanComercioPeruQA',
    API_KEY: '',
  },

  recaptchaKey: '6LeJM2kaAAAAAN7OUYsKDG3H_xSzrGx95AcF74UX',
  urlCaheList: ['v2/exchange-rates/exchange-rate?'],
  GA_TRACKING_ID: 'G-C3FMVKWWHM',
  clientIdGoogle: '915078110870-fmn3381p6tgcmvaavkfm6dpu7a4r6glj.apps.googleusercontent.com',
  leads: 'https://script.google.com/macros/s/AKfycbyCgGKml0zjsrNhz8CKdTgZEYqZtjcrrCWpwPunUO_o5yaXbv5rbo2ooaDS7UrQe48/exec',
};
